<template>
  <v-card class="pa-4" flat>
    <v-tabs v-model="tab" center-active @change="tabValue">
      <v-tab>
        {{ $t("absent.absent_per_day") }}
      </v-tab>
      <v-tab>
        {{ $t("absent.absent_per_subjects") }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <v-radio-group v-model="roleType" row>
          <v-radio :label="$t('routes.student')" value="student" />
          <v-radio :label="$t('routes.teacher')" value="teacher" />
          <v-radio :label="$t('routes.staff')" value="staff" />
        </v-radio-group>
        <v-divider />
        <Student v-if="roleStudent" :init="roleStudent" />
        <StaffTeacher
          v-if="roleTeacher"
          :showTab="true"
          :init="roleTeacher"
          :filter="{
            'employees.type': ['TEACHER'],
            'absences.date': [currentDate]
          }"
          :titleDialog="$t('absent.absent_teacher')"
          :tabName="$t('absent.teacher')"
          key="teacher"
        />
        <StaffTeacher
          v-if="roleStaff"
          :showTab="true"
          :init="roleStaff"
          :filter="{
            'employees.type': ['STAFF'],
            'absences.date': [currentDate]
          }"
          :titleDialog="$t('absent.absent_staff')"
          :tabName="$t('absent.staff')"
          key="staff"
        />
      </v-tab-item>
      <v-tab-item>
        <Header
          :idClass="idClass"
          :idGrade="idGrade"
          :idSchoolYear="idSchoolYear"
          :itemTeacher="itemTeacher"
          class="mt-5"
          @setIdClass="newValue => (idClass = newValue)"
          @setIdGrade="newValue => (idGrade = newValue)"
          @setIdSchoolYear="newValue => (idSchoolYear = newValue)"
          @setTeacher="newValue => (itemTeacher = newValue)"
        />
        <Table
          :idClass="idClass"
          :idGrade="idGrade"
          :idSchoolYear="idSchoolYear"
          :isInitData="initData"
          :itemTeacher="itemTeacher"
          @changeInitData="newValue => (initData = newValue)"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import moment from "moment/moment";
moment.locale("id");

export default {
  components: {
    Student: () => import("./Student"),
    StaffTeacher: () => import("./StaffTeacher"),
    Header: () => import("./components/absentPerSubjects/Header"),
    Table: () => import("./components/absentPerSubjects/Table")
  },
  computed: {
    roleStudent() {
      return this.roleType === "student";
    },
    roleTeacher() {
      return this.roleType === "teacher";
    },
    roleStaff() {
      return this.roleType === "staff";
    }
  },
  data() {
    return {
      tab: this.$route.query.tab | 0,
      filterAll: [],
      currentDate: moment().format("YYYY-MM-DD"),
      roleType: "student",
      itemTeacher: [],
      initData: false,
      idClass: null,
      idGrade: null,
      idSchoolYear: null
    };
  },
  watch: {
    "$route.query.tab"(n) {
      if (n) this.tab = this.$route.query.tab;
    }
  },
  methods: {
    tabValue(tab) {
      this.$router.push({ name: "Absent", query: { tab: tab } });
    }
  }
};
</script>
